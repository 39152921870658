import React from 'react'
import PropTypes from 'prop-types'
const iconSizes = {
  height: 200,
  width: 200
}

const Clients = ({ clients }) => (
  <div>
    {clients.map((client, id) => (
      <img key={id} alt={client.name} src={client.image_id} {...iconSizes}/>
    ))}
  </div>
)

Clients.propTypes = {
  clients: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      image_id: PropTypes.string
    })
  ),
}

export default Clients
